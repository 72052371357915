import React, { useEffect, useState } from "react";
import "./navbar.css";
import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../context/AppProvider";
import ViewData from "../services/ViewData";
import Footer from "../footer/Footer";

const Navbar = () => {
  const { i18n } = useTranslation();
  const context = useAppContext();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [viewSettings, setSettings] = useState({});

  const dataSettings = async () => {
    try {
      const result = await ViewData("settings.php");
      setSettings(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataSettings();
  }, []);

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    context.setDirection("rtl");
  };

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    context.setDirection("ltr");
  };

  // console.log(viewSettings);

  return (
    <div className="section_body">
      <nav>
        <div className="right">
          {context.direction == "ltr" ? (
            <li
              className="button"
              style={{ background: "transparent" }}
              onClick={ChangeAR}
            >
              AR
            </li>
          ) : (
            <li
              className="button"
              style={{ background: "transparent" }}
              onClick={ChangeEN}
            >
              EN
            </li>
          )}
        </div>

        <NavLink to="/" className="logo">
          <img src={`${LinkLikeBachend}${viewSettings?.logo}`} alt="logo" />
        </NavLink>
      </nav>

      <Outlet />

      <Footer viewSettings={viewSettings} />
    </div>
  );
};

export default Navbar;
