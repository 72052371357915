import React, { useEffect, useState } from "react";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import { toast } from "react-toastify";
import DeleteData from "../../../../components/services/DeleteData";
import { MdOutlineEdit, MdOutlineDateRange } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import UpdateStore from "./UpdateStore";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const AllStore = () => {
  const [viewStore, setviewStore] = useState([]);
  const [storeCount, setStoreCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [itemID, setItemID] = useState(false);
  const [viewItemID, setViewItemID] = useState(false);

  const dataStore = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewData("store/store.php");
      setviewStore(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCount = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store/store_count.php");
      setStoreCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewData("store/store.php", null, currentPage + 1);
      setTimeout(() => {
        setviewStore((prevData) => prevData.concat(result));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    dataStore();
    dataStoreCount();
  }, []);

  const handleDelete = async (itemId) => {
    try {
      const result = await DeleteData("store.php", itemId);

      if (result.request == "successfully") {
        toast.success("Deleted successfully");
        dataStore();
        dataStoreCount();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (itemId) => {
    setItemID(itemId);
    setViewItemID(true);
  };

  // console.log(viewStore);
  // console.log(StoreCount);

  return (
    <>
      {viewItemID && (
        <UpdateStore
          dataStore={dataStore}
          dataStoreCount={dataStoreCount}
          itemId={itemID}
          setViewItemID={setViewItemID}
        />
      )}

      <div>
        <h1>All Store</h1>

        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="full_store">
            <div className="all_store">
              {viewStore && viewStore.length > 0 ? (
                viewStore.map((item, i) => (
                  <div className="all_item_store" key={i}>
                    <div className="item_store">
                      <img
                        src={`${LinkLikeBachend}${item.img}`}
                        alt={item.title_en}
                      />
                    </div>
                    <div className="data_product">
                      <small className="small_1">{item.title_en}</small>
                      <small>
                        <MdOutlineDateRange /> {item.date_add}
                      </small>
                    </div>

                    <div className="submit_delete">
                      <MdOutlineEdit
                        onClick={() => handleUpdate(item.id)}
                        className="delete"
                      />

                      <IoMdCloseCircle
                        onClick={() => handleDelete(item.id)}
                        className="delete"
                      />
                    </div>
                  </div>
                ))
              ) : (
                <h1>No Data</h1>
              )}
            </div>

            <div className="div_load_more">
              {viewStore &&
                viewStore.length > 0 &&
                parseInt(storeCount) !== viewStore.length && (
                  <button
                    className="button"
                    onClick={dataStoreLoadMore}
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                  >
                    {!isDisabledLoadMore ? "More" : "loading..."}
                  </button>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllStore;
