import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import background_upload from "../../../../img/background_upload.webp";
import Img from "../../../../components/input/Img";
import Text from "../../../../components/input/Text";
import "./addStore.css";
import JoditEditorInput from "../../../../components/input/JoditEditorInput";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import CreateData from "../../../../components/services/CreateData";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const AddStore = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const [isBackgroundImg, setBackgroundImg] = useState("");
  const [viewCategory, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);

    const category = viewCategory.find((item) => item.id === categoryId);
    if (category && category.sub_categories) {
      setSelectedSubCategories(category.sub_categories);
    } else {
      setSelectedSubCategories([]);
    }
  };

  const handleFileChange = (e) => {
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
  };

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const dataStoreCategory = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    dataStoreCategory();
  }, []);

  const onSubmit = async (data, e) => {
    if (methods.watch("price") > 0) {
      setDisabled(true);

      try {
        const result = await CreateData("add_store.php", e);

        setDisabled(false);

        if (result.request == "successfully") {
          setDisabled(false);

          toast.success("Added successfully");

          methods.reset();
          setBackgroundImg("");

          dataStoreCategory();
        } else {
          toast.warning("An problem occurred");

          setDisabled(false);
          console.log(result);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An problem occurred");

        setDisabled(false);
      }
    } else {
      toast.warning("Add an amount greater than 0");
      methods.setFocus("price");
    }
  };

  console.log(viewCategory);

  return (
    <div className="form add_store">
      <h1>Add Store</h1>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="background_upload">
              <Img
                name="img"
                type="file"
                accept="image/*"
                required={true}
                change={handleFileChange}
              />

              <img
                src={
                  isBackgroundImg != "" ? isBackgroundImg : background_upload
                }
                alt="background_upload"
              />
            </div>

            <div className="inputBox">
              <Text
                name="title_en"
                type="text"
                readOnly={false}
                required={true}
                placeholder="text en"
                className="input"
              />

              <Text
                name="title_ar"
                type="text"
                readOnly={false}
                required={true}
                placeholder="text ar"
                className="input"
              />

              <div className="price_currency">
                <Text
                  name="price"
                  type="number"
                  readOnly={false}
                  required={true}
                  placeholder="price"
                  className="input price"
                />

                <span>QR</span>
              </div>
            </div>

            <fieldset>
              <legend>description EN</legend>

              <JoditEditorInput
                name="description_en"
                editorConfig={editorConfigEN}
              />
            </fieldset>

            <fieldset>
              <legend>description AR</legend>

              <JoditEditorInput
                name="description_ar"
                editorConfig={editorConfigAR}
              />
            </fieldset>

            <fieldset className="Category">
              <legend>Category</legend>
              <select
                name="category"
                id="category"
                required
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="">select category</option>
                {viewCategory &&
                  viewCategory.length > 0 &&
                  viewCategory.map((item, i) => (
                    <option value={item.id} key={item.id}>
                      {item.text_en}
                    </option>
                  ))}
              </select>
            </fieldset>

            {selectedSubCategories.length > 0 && (
              <fieldset className="Category">
                <legend>Sub Category</legend>
                <select
                  name="sub_category"
                  id="sub_category"
                  required
                  className="Category"
                >
                  <option value="">sub category</option>
                  {selectedSubCategories.map((subItem) => (
                    <option value={subItem.id} key={subItem.id}>
                      {subItem.text_en}
                    </option>
                  ))}
                </select>
              </fieldset>
            )}

            <input
              type="submit"
              style={{
                opacity: isDisabled ? 0.3 : 1,
                background: "var(--bl)",
              }}
              disabled={isDisabled}
              value={isDisabled ? "Loading..." : "Add"}
              className="button"
            />
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default AddStore;
