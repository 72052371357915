import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import ViewData from "../services/ViewData";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [direction, setDirection] = useState("rtl");
  const WhatsAppNumber = 97470100403;

  let cookiesLoginAdmin = Cookies.get("login_admin")
    ? Cookies.get("login_admin")
    : null;

  const [admin, setAdmin] = useState(cookiesLoginAdmin);

  const loginAdmin = (newtoken) => {
    Cookies.set("login_admin", newtoken, { expires: 1 });

    setAdmin(Cookies.get("login_admin"));
  };

  const logOutAdmin = () => {
    Cookies.remove("login_admin");

    window.location.reload();
  };

  const handelDataAdmin = async (newtoken) => {
    try {
      const response = await ViewData("verification_admin.php", newtoken);

      if (response.request != "successfully") {
        setAdmin(null);
        Cookies.remove("login_admin");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (cookiesLoginAdmin) {
      handelDataAdmin(cookiesLoginAdmin);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        admin,
        loginAdmin,
        logOutAdmin,
        direction,
        setDirection,
        WhatsAppNumber,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
