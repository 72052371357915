import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoMdCloseCircle } from "react-icons/io";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import { toast } from "react-toastify";
import Img from "../../../../components/input/Img";
import Text from "../../../../components/input/Text";
import JoditEditorInput from "../../../../components/input/JoditEditorInput";
import Checkbox from "../../../../components/input/Checkbox";
import UpdateData from "../../../../components/services/UpdateData";

const UpdateStore = ({ dataStore, dataStoreCount, itemId, setViewItemID }) => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImgUpdate, setBackgroundImgUpdate] = useState("");
  const [viewStore, setViewStore] = useState([]);
  const [viewCategory, setCategory] = useState([]);
  const [viewCategoryChampion, setCategoryChampion] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);

    const category = viewCategory.find((item) => item.id === categoryId);
    if (category && category.sub_categories) {
      setSelectedSubCategories(category.sub_categories);
    } else {
      setSelectedSubCategories([]);
    }
  };

  const handleFileChangeUpdate = (e) => {
    setBackgroundImgUpdate(URL.createObjectURL(e.target.files[0]));
  };

  const [editorConfigEN] = useState({
    placeholder: "",
    direction: "ltr",
    minHeight: "500",
  });

  const [editorConfigAR] = useState({
    placeholder: "",
    direction: "rtl",
    minHeight: "500",
  });

  const dataStoreID = async () => {
    setLoading(true);
    try {
      const result = await ViewData("store/store.php", itemId, null);
      setViewStore(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCategory = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataCategoryChampion = async () => {
    setLoading(true);

    try {
      const result = await ViewData("admin_category_store.php", itemId);
      setCategoryChampion(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    dataStoreID();
    dataStoreCategory();
    dataCategoryChampion();
  }, []);

  const handleClose = async () => {
    dataStore();
    dataStoreCount();
    setViewItemID(false);
  };

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("store.php", e, itemId);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Updated successfully");

        methods.reset();
        setBackgroundImgUpdate("");

        handleClose();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  // console.log(viewStore);
  // console.log(viewCategory);
  // console.log(viewCategoryChampion);
  // console.log(selectedCategory);
  // console.log(selectedSubCategories);

  return (
    <div className="position_fixed">
      <IoMdCloseCircle onClick={handleClose} className="handleClose" />

      <>
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="div_position_fixed form add_store update_store">
            {viewStore &&
              viewStore.length > 0 &&
              viewStore.map((item, i) => (
                <FormProvider {...methods} key={i}>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                  >
                    <div className="background_upload">
                      <Img
                        name="img"
                        type="file"
                        accept="image/*"
                        required={false}
                        change={handleFileChangeUpdate}
                      />

                      <img
                        src={
                          isBackgroundImgUpdate != ""
                            ? isBackgroundImgUpdate
                            : `${LinkLikeBachend}${item.img}`
                        }
                        alt="background_upload"
                      />
                    </div>

                    <div className="inputBox">
                      <Text
                        name="title_en"
                        type="text"
                        readOnly={false}
                        required={true}
                        placeholder="text en"
                        className="input"
                        value={item.title_en}
                      />

                      <Text
                        name="title_ar"
                        type="text"
                        readOnly={false}
                        required={true}
                        placeholder="text ar"
                        className="input"
                        value={item.title_ar}
                      />

                      <div className="price_currency">
                        <Text
                          name="price"
                          type="number"
                          readOnly={false}
                          required={true}
                          placeholder="price"
                          className="input price"
                          value={item.price}
                        />

                        <span>QR</span>
                      </div>
                    </div>

                    <fieldset>
                      <legend>description EN</legend>

                      <JoditEditorInput
                        name="description_en"
                        editorConfig={editorConfigEN}
                        value={item.description_en}
                      />
                    </fieldset>

                    <fieldset>
                      <legend>description AR</legend>

                      <JoditEditorInput
                        name="description_ar"
                        editorConfig={editorConfigAR}
                        value={item.description_ar}
                      />
                    </fieldset>

                    <fieldset className="Category">
                      <legend>Category</legend>
                      <select
                        name="category"
                        id="category"
                        required
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value="">select category</option>
                        {viewCategory &&
                          viewCategory.length > 0 &&
                          viewCategory.map((item, i) => (
                            <option value={item.id} key={item.id}>
                              {item.text_en}
                            </option>
                          ))}
                      </select>
                    </fieldset>

                    {selectedSubCategories.length > 0 && (
                      <fieldset className="Category">
                        <legend>Sub Category</legend>
                        <select
                          name="sub_category"
                          id="sub_category"
                          required
                          className="Category"
                        >
                          <option value="">sub category</option>
                          {selectedSubCategories.map((subItem) => (
                            <option value={subItem.id} key={subItem.id}>
                              {subItem.text_en}
                            </option>
                          ))}
                        </select>
                      </fieldset>
                    )}

                    <input
                      type="submit"
                      style={{
                        opacity: isDisabled ? 0.3 : 1,
                        background: "var(--bl)",
                      }}
                      disabled={isDisabled}
                      value={isDisabled ? "Loading..." : "Update"}
                      className="button"
                    />
                  </form>
                </FormProvider>
              ))}
          </div>
        )}
      </>
    </div>
  );
};

export default UpdateStore;
