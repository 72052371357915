import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Text from "../../../../components/input/Text";
import background_upload from "../../../../img/background_upload.webp";
import Img from "../../../../components/input/Img";
import "./storeCategory.css";
import ViewData from "../../../../components/services/ViewData";
import Loading from "../../../../components/loading/Loading";
import CreateData from "../../../../components/services/CreateData";
import DeleteData from "../../../../components/services/DeleteData";
import { IoMdCloseCircle } from "react-icons/io";
import UpdateStoreSubCategory from "./UpdateStoreSubCategory";
import { MdOutlineEdit } from "react-icons/md";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const AddStoreCategory = () => {
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const [isBackgroundImg, setBackgroundImg] = useState("");
  const [viewCategory, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [itemID, setItemID] = useState(false);
  const [viewItemID, setViewItemID] = useState(false);
  const [isNoSub, setNoSub] = useState(0);

  const handleFileChange = (e) => {
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
  };

  const dataStoreCategory = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    dataStoreCategory();
  }, []);

  const onSubmit = async (data, e) => {
    setDisabled(true);

    try {
      const result = await CreateData("store_category.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        setDisabled(false);

        toast.success("Added successfully");

        methods.reset();
        setBackgroundImg("");
        dataStoreCategory();
      } else {
        toast.warning("An problem occurred");

        setDisabled(false);
        console.log(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const result = await DeleteData("store_category.php", itemId);

      if (result.request == "successfully") {
        toast.success("Deleted successfully");
        dataStoreCategory();
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (itemId, noSub) => {
    setItemID(itemId);
    setViewItemID(true);
    setNoSub(noSub);
  };

  // console.log(viewCategory);

  return (
    <>
      {viewItemID && (
        <UpdateStoreSubCategory
          dataStoreCategory={dataStoreCategory}
          itemId={itemID}
          setViewItemID={setViewItemID}
          viewCategory={viewCategory}
          isNoSub={isNoSub}
        />
      )}

      <div className="form">
        <h1>Store Category</h1>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            {error ? (
              "Error"
            ) : load ? (
              <Loading />
            ) : (
              <fieldset className="Category">
                <legend>Category</legend>

                <select name="parent_id" id="category">
                  <option value="">Category</option>
                  {viewCategory &&
                    viewCategory.length > 0 &&
                    viewCategory.map((item, i) => (
                      <option value={item.id} key={item.id}>
                        sub | {item.text_en}
                      </option>
                    ))}
                </select>
              </fieldset>
            )}

            <div className="background_upload">
              <Img
                name="img"
                type="file"
                accept="image/*"
                required={true}
                change={handleFileChange}
              />

              <img
                src={
                  isBackgroundImg != "" ? isBackgroundImg : background_upload
                }
                alt="background_upload"
              />
            </div>

            <div className="inputBox">
              <Text
                name="text_en"
                type="text"
                readOnly={false}
                required={true}
                placeholder="Category text en"
                className="input"
              />
            </div>

            <div className="inputBox">
              <Text
                name="text_ar"
                type="text"
                readOnly={false}
                required={true}
                placeholder="Category text ar"
                className="input"
              />
            </div>

            <input
              type="submit"
              style={{ opacity: isDisabled ? 0.3 : 1, background: "var(--bl)" }}
              disabled={isDisabled}
              value={isDisabled ? "Loading..." : "Add"}
              className="button"
            />
          </form>
        </FormProvider>

        <div className="full_all_category">
          {viewCategory &&
            viewCategory.length > 0 &&
            viewCategory.map((item, i) => (
              <div key={i} className="all_category">
                <div className="category">
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={item.text_en}
                  />
                  <p>{item.text_en}</p>
                  <p>|</p>
                  <p>{item.text_ar}</p>

                  <div className="submit_delete">
                    <MdOutlineEdit
                      onClick={() =>
                        handleUpdate(item.id, item.sub_categories.length)
                      }
                      className="delete"
                    />

                    <IoMdCloseCircle
                      onClick={() => handleDelete(item.id)}
                      className="delete"
                    />
                  </div>
                </div>

                {item.sub_categories && item.sub_categories.length > 0 && (
                  <div className="sub-categories">
                    {item.sub_categories.map((subItem, j) => (
                      <div key={j} className="sub-category">
                        <img
                          src={`${LinkLikeBachend}${subItem.img}`}
                          alt={subItem.text_en}
                        />
                        <p>{subItem.text_en}</p>
                        <p>|</p>
                        <p>{subItem.text_ar}</p>

                        <div className="submit_delete">
                          <MdOutlineEdit
                            onClick={() => handleUpdate(subItem.id)}
                            className="delete"
                          />

                          <IoMdCloseCircle
                            onClick={() => handleDelete(subItem.id)}
                            className="delete"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AddStoreCategory;
