import axios from "axios";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
const validation = process.env.REACT_APP_VALIDATION;

const ViewDataCategory = async (
  urlPage,
  certainly_or_soon,
  currentPage,
  category
) => {

  const formData = new FormData();
  formData.append("validation", validation);

  if (certainly_or_soon) {
    formData.append("certainly_or_soon", certainly_or_soon);
  }

  if (currentPage) {
    formData.append("page", currentPage);
  }

  if (category) {
    formData.append("category", category);
  }

  try {
    const response = await axios.post(
      `${LinkLikeBachend}read/${urlPage}`,
      formData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default ViewDataCategory;
