export const arabicDataAr = {
  store: "خدماتنا",
  store_1: "افضل خدمات الضيافة في قطر",
  waiting: "انتظار",
  currency: "ريال قطري",
  more: "المزيد",
  loading: "تحميل...",
  no_data: "لا يوجد بيانات",
  add_basket: "للطلب الان",
};

export default arabicDataAr;
