import React, { useEffect, useRef, useState } from "react";
import Loading from "../../components/loading/Loading";
import { useTranslation } from "react-i18next";
import all_category_d from "../../img/all_category_d.webp";
import "./store.css";
import ViewDataCategory from "../../components/services/ViewDataCategory";
import ViewData from "../../components/services/ViewData";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { useAppContext } from "../../components/context/AppProvider";
import ViewStore from "./view/ViewStore";
import { IoIosStar } from "react-icons/io";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import HomeSlider from "./../homeSlider/HomeSlider";

const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const Store = () => {
  const [store, setStore] = useState([]);
  const [storeCount, setStoreCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewCategory, setCategory] = useState([]);
  const [idCategory, setIdCategory] = useState("");
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const { i18n, t } = useTranslation();
  const { WhatsAppNumber } = useAppContext();
  const [idItem, setIdItem] = useState("");
  const [openStore, setOpenStore] = useState(false);
  const scrollRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const stars = Array.from({ length: 5 });
  const [openCategoryId, setOpenCategoryId] = useState("");

  const dataStore = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const result = await ViewDataCategory(
        "store_category/store_category.php",
        "1"
      );
      setStore(result.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCount = async (category) => {
    try {
      const result = await ViewDataCategory(
        "store_category/store_category.php",
        "1",
        null,
        category
      );
      // console.log(result);

      setStoreCount(result.total);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreCategory = async () => {
    setCurrentPage(1);

    try {
      const result = await ViewData("store_category.php");
      setCategory(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const dataStoreLoadMore = async () => {
    setDisabledLoadMore(true);
    try {
      const result = await ViewDataCategory(
        "store_category/store_category.php",
        "1",
        currentPage + 1,
        idCategory
      );

      dataStoreCount(idCategory);

      setTimeout(() => {
        setStore((prevData) => prevData.concat(result.data));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 2000);
    } catch (error) {
      setError(error);
    }
  };

  const handleCategory = async (category) => {
    setCurrentPage(1);
    setIdCategory(category);

    try {
      const result = await ViewDataCategory(
        "store_category/store_category.php",
        "1",
        null,
        category
      );
      dataStoreCount(category);
      setStore(result.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const handleView = (itemId) => {
    setOpenStore(true);
    setIdItem(itemId);
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    dataStore();
    dataStoreCount();
    dataStoreCategory();
  }, []);

  const handleOrderNow = (itemId) => {
    const itemOrder = store.find((item) => item.id === itemId);
    const { title_en, title_ar, price } = itemOrder;
    const titleLang =
      i18n.language === "ar" ? `المنتج: ${title_ar}` : `product: ${title_en}`;

    const priceLang =
      i18n.language === "ar"
        ? `السعر: ${price} ريال قطري`
        : `price: ${price} QR`;

    const formattedData = `${titleLang}\n\n${priceLang}`;

    const whatsappMessage = encodeURIComponent(formattedData);

    const whatsappUrl = `https://wa.me/${WhatsAppNumber}/?text=${whatsappMessage}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseLeave = () => {
    isDragging.current = false;
  };

  const handleCategoryClick = (id) => {
    if (openCategoryId === id) {
      setOpenCategoryId("");
    } else {
      setOpenCategoryId(id);
    }
  };

  // console.log(store);
  // console.log(storeCount);
  // console.log(viewCategory);
  // console.log(idCategory);
  // console.log(openCategoryId);

  return (
    <>
      <HomeSlider />

      {openStore && <ViewStore idItem={idItem} setOpenStore={setOpenStore} />}

      <div className="pages pages_full_store">
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <div className="full_store">
            <div className="full_store_category">
              <h2>{t("store")}</h2>
              <p>{t("store_1")}</p>
              <div className="full_category">
                <div
                  className="categories"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseLeave}
                  ref={scrollRef}
                >
                  <button
                    className={`category ${
                      openCategoryId === "" ? "active" : ""
                    }`}
                    onClick={() => {
                      handleCategory("");
                      handleCategoryClick("");
                    }}
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                  >
                    <img src={all_category_d} alt="all_category" />
                  </button>

                  {viewCategory &&
                    viewCategory.length > 0 &&
                    viewCategory.map((item, i) => (
                      <div key={i} className="all_category">
                        <button
                          className={`category ${
                            openCategoryId === item.id ? "active" : ""
                          }`}
                          disabled={isDisabledLoadMore}
                          style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                          onClick={() => handleCategoryClick(item.id)}
                        >
                          <img
                            src={`${LinkLikeBachend}${item.img}`}
                            alt={item.text_en}
                          />
                          {i18n.language === "en" ? item.text_en : item.text_ar}{" "}
                          {openCategoryId === item.id ? (
                            <IoMdArrowDropup />
                          ) : (
                            <IoMdArrowDropdown />
                          )}
                        </button>

                        {item.sub_categories &&
                          item.sub_categories.length > 0 &&
                          openCategoryId === item.id && (
                            <div className="sub-categories">
                              {item.sub_categories.map((subItem, j) => (
                                <button
                                  key={j}
                                  className="sub-category"
                                  disabled={isDisabledLoadMore}
                                  style={{
                                    opacity: isDisabledLoadMore ? 0.3 : 1,
                                  }}
                                  onClick={() => {
                                    handleCategory(subItem.id);
                                    handleCategoryClick(subItem.id);
                                  }}
                                >
                                  <img
                                    src={`${LinkLikeBachend}${subItem.img}`}
                                    alt={item.text_en}
                                  />

                                  {i18n.language === "en"
                                    ? subItem.text_en
                                    : subItem.text_ar}
                                </button>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="all_store">
              {store && store?.length > 0 ? (
                store?.map((item, i) => (
                  <div className="all_item_store" key={i}>
                    <div
                      className="item_store"
                      onClick={() => handleView(item.id)}
                    >
                      <img
                        src={`${LinkLikeBachend}${item.img}`}
                        alt={item.title_en}
                      />
                    </div>

                    <div className="data_product">
                      <small className="small_1">
                        {i18n.language == "en" ? item.title_en : item.title_ar}
                      </small>

                      <div className="star">
                        {stars.map((_, index) => (
                          <IoIosStar key={index} />
                        ))}
                      </div>

                      <small>
                        {item?.price} {t("currency")}
                      </small>
                    </div>

                    <div className="add_basket">
                      <button
                        className="button add_basket"
                        onClick={() => handleOrderNow(item.id)}
                      >
                        {t("add_basket")}
                        <MdOutlineFavoriteBorder />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h1>{t("no_data")}</h1>
              )}
            </div>
            <div className="div_load_more">
              {store &&
                store?.length > 0 &&
                parseInt(storeCount) !== store?.length && (
                  <button
                    className="button"
                    onClick={dataStoreLoadMore}
                    disabled={isDisabledLoadMore}
                    style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
                  >
                    {!isDisabledLoadMore ? t("more") : t("loading")}
                  </button>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Store;
