import React, { useEffect, useState } from "react";
import ViewData from "../services/ViewData";
import Loading from "../loading/Loading";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const SocialMediaLinks = () => {
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [viewSocialMedia, setSocialMedia] = useState([]);

  const dataSocialMedia = async () => {
    try {
      const result = await ViewData("social_media.php");
      setSocialMedia(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    dataSocialMedia();
  }, []);

  return (
    <div className="social_media_links">
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <>
          {viewSocialMedia &&
            viewSocialMedia.length > 0 &&
            viewSocialMedia?.map((item, i) => (
              <a
                className="social_media"
                target="_blank"
                key={i}
                href={item.link}
              >
                <img
                  src={`${LinkLikeBachend}${item.img}`}
                  alt={`social_media_${i}`}
                />
              </a>
            ))}
        </>
      )}
    </div>
  );
};

export default SocialMediaLinks;
