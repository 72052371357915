import React, { useEffect } from "react";
import NotFound from "./components/notFound/NotFound";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import LoginAdmin from "./admin/LoginAdmin";
import Dashboard from "./admin/pages/dashboard/Dashboard";
import Navigation from "./admin/navigation/Navigation";
import { useAppContext } from "./components/context/AppProvider";
import SocialMedia from "./admin/pages/socialMedia/SocialMedia";
import Settings from "./admin/pages/settings/Settings";
import AdminSlider from "./admin/pages/adminSlider/AdminSlider";
import Store from "./page/store/Store";
import AllStore from "./admin/pages/store/addStore/AllStore";
import AddStore from "./admin/pages/store/addStore/AddStore";
import AddStoreCategory from "./admin/pages/store/storeCategory/AddStoreCategory";

const App = () => {
  const location = useLocation();
  const context = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      className={`direction direction_${context.direction}`}
      style={{ direction: context.direction }}
    >
      <Routes>
        <Route path="/admin" element={<LoginAdmin />} />

        <Route path="/dashboard" element={<Navigation />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="settings" element={<Settings />} />
          <Route path="admin-social-media" element={<SocialMedia />} />
          <Route path="admin-slider" element={<AdminSlider />} />

          <Route path="admin-store" element={<AllStore />} />
          <Route path="admin-add-store" element={<AddStore />} />
          <Route path="admin-add-category" element={<AddStoreCategory />} />
        </Route>

        <Route path="/" element={<Navbar />}>
          <Route path="/" element={<Store />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
