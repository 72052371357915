import React from "react";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";
import "./footer.css";
import Copyrights from "../copyrights/Copyrights";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const Footer = ({ viewSettings }) => {
  return (
    <>
      <div className="footer">
        <SocialMediaLinks />

        <div>
          <img
            className="logo"
            src={`${LinkLikeBachend}${viewSettings?.logo}`}
            alt={viewSettings?.copyright_en}
          />
        </div>
      </div>

      <Copyrights />
    </>
  );
};

export default Footer;
