import React, { useEffect, useState } from "react";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import "../store.css";
import { useTranslation } from "react-i18next";
import { IoMdPricetags } from "react-icons/io";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { useAppContext } from "../../../components/context/AppProvider";
import { IoMdCloseCircle } from "react-icons/io";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

const ViewStore = ({ idItem, setOpenStore }) => {
  const [viewStore, setViewStore] = useState([]);
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const { i18n, t } = useTranslation();
  const { WhatsAppNumber } = useAppContext();

  const handleClose = async () => {
    setOpenStore(false);
    document.body.style.overflow = "";
  };

  const dataStore = async () => {
    setLoading(true);
    try {
      const result = await ViewData("store/store.php", idItem, null);
      setViewStore(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    dataStore();
  }, []);

  // console.log(viewStore);

  const handleOrderNow = (itemId) => {
    const itemOrder = viewStore.find((item) => item.id === itemId);

    const { title_en, title_ar, price } = itemOrder;
    const titleLang =
      i18n.language === "ar" ? `المنتج: ${title_ar}` : `product: ${title_en}`;

    const priceLang =
      i18n.language === "ar"
        ? `السعر: ${price} ريال قطري`
        : `price: ${price} QR`;

    const formattedData = `${titleLang}\n\n${priceLang}`;

    const whatsappMessage = encodeURIComponent(formattedData);

    const whatsappUrl = `https://wa.me/${WhatsAppNumber}/?text=${whatsappMessage}`;

    window.open(whatsappUrl, "_blank");
  };

  // console.log(idItem);

  return (
    <div className="position_fixed">
      <IoMdCloseCircle onClick={handleClose} className="handleClose" />

      <div className="pages view_store">
        {error ? (
          "Error"
        ) : load ? (
          <Loading />
        ) : (
          <>
            {viewStore &&
              viewStore.map((item, i) => (
                <div className="item_view_store" key={i}>
                  <img
                    src={`${LinkLikeBachend}${item.img}`}
                    alt={item?.description_en}
                  />

                  <div className="fixed_information">
                    {item?.price > 0 && (
                      <small>
                        <IoMdPricetags /> {item?.price} {t("currency")}
                      </small>
                    )}
                  </div>

                  {i18n.language == "en" ? (
                    <>
                      <h1>{item?.title_en}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description_en,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <h1>{item?.title_ar}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description_ar,
                        }}
                      />
                    </>
                  )}

                  <div className="add_basket">
                    <button
                      className="button add_basket"
                      onClick={() => handleOrderNow(item.id)}
                    >
                      {t("add_basket")}
                      <MdOutlineFavoriteBorder />
                    </button>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ViewStore;
