export const englishDataEn = {
  store: "Our services",
  store_1: "best hospitality service in Qatar",
  waiting: "waiting",
  currency: "QR",
  more: "More",
  loading: "Loading...",
  no_data: "No Data",
  add_basket: "Order Now",
};

export default englishDataEn;
