import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { TbBuildingStore } from "react-icons/tb";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";

const Dashboard = () => {
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [storeCount, setStoreCount] = useState(0);

  const dataStoreCount = async () => {
    setLoading(true);

    try {
      const result = await ViewData("store/store_count.php");
      setStoreCount(result.request);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    dataStoreCount();
  }, []);

  return (
    <>
      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <div className="dashboard">
          <div className="dashboard_deposit">
            <div className="deposit_total">
              <TbBuildingStore />
              <p>{storeCount}</p>

              <small>Total products</small>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
