import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./settings.css";
import Text from "../../../components/input/Text";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Img from "../../../components/input/Img";
import ViewData from "../../../components/services/ViewData";
import Loading from "../../../components/loading/Loading";
import UpdateData from "../../../components/services/UpdateData";

const Settings = () => {
  const [typeText, setTypeText] = useState("password");
  const [isDisabled, setDisabled] = useState(false);
  const methods = useForm();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const [isBackgroundImg, setBackgroundImg] = useState("");
  const [viewSettings, setSettings] = useState({});
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);

  const handleFileChange = (e) => {
    setBackgroundImg(URL.createObjectURL(e.target.files[0]));
  };

  const dataSettings = async () => {
    setLoading(true);

    try {
      const result = await ViewData("settings.php");
      setSettings(result);
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    dataSettings();
  }, []);

  const onUpdate = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("change_password_admin.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        toast.success("Password has been updated");

        methods.setValue("password_admin", "");
      } else {
        toast.error("An problem occurred");
        console.error(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  const onUpdateSettings = async (data, e) => {
    setDisabled(true);

    try {
      const result = await UpdateData("settings.php", e);

      setDisabled(false);

      if (result.request == "successfully") {
        toast.success("Updated successfully");
      } else {
        toast.error("An error occurred");
        console.error(result);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An problem occurred");

      setDisabled(false);
    }
  };

  const handleChangepPassword = () => {
    typeText == "password" ? setTypeText("text") : setTypeText("password");
  };

  // console.log(viewSettings);

  return (
    <div className="settings">
      <h1>Settings</h1>

      {error ? (
        "Error"
      ) : load ? (
        <Loading />
      ) : (
        <>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onUpdateSettings)}
              encType="multipart/form-data"
            >
              <label htmlFor="logo">logo</label>
              <div className="background_upload">
                <Img
                  name="logo"
                  type="file"
                  accept="image/*"
                  required={false}
                  change={handleFileChange}
                />

                <img
                  src={
                    isBackgroundImg != ""
                      ? isBackgroundImg
                      : `${LinkLikeBachend}${viewSettings?.logo}`
                  }
                  alt="background_upload"
                />
              </div>

              <div className="inputBox">
                <Text
                  name="copyright_en"
                  type="text"
                  readOnly={false}
                  required={true}
                  placeholder="copyright en"
                  className="input"
                  value={viewSettings?.copyright_en}
                />
              </div>

              <div className="inputBox">
                <Text
                  name="copyright_ar"
                  type="text"
                  readOnly={false}
                  required={true}
                  placeholder="copyright ar"
                  className="input"
                  value={viewSettings?.copyright_ar}
                />
              </div>

              <input
                type="submit"
                style={{
                  opacity: isDisabled ? 0.3 : 1,
                  background: "var(--bl)",
                }}
                disabled={isDisabled}
                value="Save"
                className="button"
              />
            </form>
          </FormProvider>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onUpdate)}>
              <div className="password_eye">
                <Text
                  name="password_admin"
                  type={typeText}
                  readOnly={false}
                  required={true}
                  placeholder="password"
                  className="input"
                />

                {typeText == "password" ? (
                  <FaEye onClick={handleChangepPassword} />
                ) : (
                  <FaEyeSlash onClick={handleChangepPassword} />
                )}
              </div>

              <input
                type="submit"
                style={{
                  opacity: isDisabled ? 0.3 : 1,
                  background: "var(--bl)",
                }}
                disabled={isDisabled}
                value="Save"
                className="button"
              />
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default Settings;
